.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@import url("https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@100;300;400;500;700;800;900&display=swap");

/** {*/
/*    margin: 0;*/
/*    padding: 0;*/
/*    box-sizing: border-box;*/
/*    font-family: "Alegreya Sans", sans-serif;*/
/*}*/

/*body {*/
/*    background: #343541;*/
/*}*/

/*#app {*/
/*    width: 100vw;*/
/*    height: 100vh;*/
/*    background: #343541;*/

/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*    justify-content: space-between;*/
/*}*/

/*#chat_container {*/
/*    flex: 1;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    overflow-y: scroll;*/

/*    display: flex;*/
/*    flex-direction: column;*/
/*    gap: 10px;*/

/*    -ms-overflow-style: none;*/
/*    scrollbar-width: none;*/

/*    padding-bottom: 20px;*/
/*    scroll-behavior: smooth;*/
/*}*/

/*!* hides scrollbar *!*/
/*#chat_container::-webkit-scrollbar {*/
/*    display: none;*/
/*}*/

/*.wrapper {*/
/*    width: 100%;*/
/*    padding: 15px;*/
/*}*/

/*.ai {*/
/*    background: #40414F;*/
/*}*/

/*.chat {*/
/*    width: 100%;*/
/*    max-width: 1280px;*/
/*    margin: 0 auto;*/

/*    display: flex;*/
/*    flex-direction: row;*/
/*    align-items: flex-start;*/
/*    gap: 10px;*/
/*}*/

/*.profile {*/
/*    width: 36px;*/
/*    height: 36px;*/
/*    border-radius: 5px;*/

/*    background: #5436DA;*/

/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*}*/

/*.ai .profile {*/
/*    background: #10a37f;*/
/*}*/

/*.profile img {*/
/*    width: 60%;*/
/*    height: 60%;*/
/*    object-fit: contain;*/
/*}*/

/*.message {*/
/*    flex: 1;*/

/*    color: #dcdcdc;*/
/*    font-size: 20px;*/

/*    max-width: 100%;*/
/*    overflow-x: scroll;*/

/*    !**/
/*     * white space refers to any spaces, tabs, or newline characters that are used to format the CSS code*/
/*     * specifies how white space within an element should be handled. It is similar to the "pre" value, which tells the browser to treat all white space as significant and to preserve it exactly as it appears in the source code.*/
/*     * The pre-wrap value allows the browser to wrap long lines of text onto multiple lines if necessary.*/
/*     * The default value for the white-space property in CSS is "normal". This tells the browser to collapse multiple white space characters into a single space, and to wrap text onto multiple lines as needed to fit within its container.*/
/*    *!*/
/*    white-space: pre-wrap;*/

/*    -ms-overflow-style: none;*/
/*    scrollbar-width: none;*/
/*}*/

/*!* hides scrollbar *!*/
/*.message::-webkit-scrollbar {*/
/*    display: none;*/
/*}*/

/*form {*/
/*    width: 100%;*/
/*    max-width: 1280px;*/
/*    margin: 0 auto;*/
/*    padding: 10px;*/
/*    background: #40414F;*/

/*    display: flex;*/
/*    flex-direction: row;*/
/*    gap: 10px;*/
/*}*/

/*textarea {*/
/*    width: 100%;*/

/*    color: #fff;*/
/*    font-size: 18px;*/

/*    padding: 10px;*/
/*    background: transparent;*/
/*    border-radius: 5px;*/
/*    border: none;*/
/*    outline: none;*/
/*}*/

/*button {*/
/*    outline: 0;*/
/*    border: 0;*/
/*    cursor: pointer;*/
/*    background: transparent;*/
/*}*/

/*form img {*/
/*    width: 30px;*/
/*    height: 30px;*/
/*}*/

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Alegreya Sans", sans-serif;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

ul {
  list-style: none;
}

/*=======================================dark mode=======================================*/

:root {
  --hue-color: 239;

  --background-color: hsl(var(--hue-color), 100%, 100%);
  --input-background-color: hsl(var(--hue-color), 100%, 100%);
  --text-color: hsl(var(--hue-color), 0%, 28%);
  --background-color-2: hsl(var(--hue-color), 85%, 98%);
  --bg-color-hover: hsl(var(--hue-color), 50%, 92%);
}

body.dark-theme {
  --background-color: hsl(var(--hue-color), 8%, 25%);
  --input-background-color: hsl(var(--hue-color), 8%, 35%);
  --text-color: hsl(var(--hue-color), 100%, 100%);
  --background-color-2: hsl(var(--hue-color), 8%, 32%);
  --bg-color-hover: hsl(var(--hue-color), 0%, 14%);
}

.main {
  display: flex;
}

.nav {
  width: 20%;
  height: 100vh;
  background-color: rgb(32, 33, 35);
  display: block !important;
}

.nav_content {
  height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.newChat_button {
  display: flex;
  justify-content: center;
}

.newChat_button button {
  background: none;
  color: white;
  text-align: left;
  border: 1px solid hsla(0, 0%, 100%, 0.2);
  width: 90%;
  margin-top: 4%;
  border-radius: 7px;
  padding: 14px;
  cursor: pointer;
  transition: .3s;
}

.newChat_button button:hover {
  background-color: rgb(41, 41, 41);
}

span {
  height: 1px;
  background: hsla(0, 0%, 100%, 0.2);
  width: 92%;
  margin: auto;
  display: block;
}

.nav_links {
  margin: 7px 0;
}

.nav_items {
  overflow: scroll;
}

.nav_items::-webkit-scrollbar {
  display: none;
}

.nav_items ul {
  /*margin-left: 3%;*/
  color: white;
}

.nav_items ul li {
  margin-right: 7px;
  padding: 13px;
  transition: .3s;
}

.nav_items ul li:hover {
  background-color: hsla(0, 0%, 100%, 0.5);
  border-radius: 7px;
}

.nav_items ul li a {
  color: white;
  font-size: 14px;
}

.header_toggle {
  display: none;
}

.header_close {
  display: none;
}

#close-menu {
  display: none;
}

.chat {
  width: 100%;
  background-color: var(--background-color);
  padding: 20px 20px 0 20px;
  height: 100vh;
}

.chat_main {
  /*height: 100vh;*/
  /*display: flex;*/
  /*justify-content: space-around;*/
  /*flex-direction: column;*/
}

.chat_head {
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat_head h1 {
  color: var(--text-color);
  margin-top: 5%;
  font-size: 40px;
}

.chat_data {
  width: 100%;
  margin: auto;
}

.chat_col {
  color: var(--text-color);
  /*display: flex;*/
  align-items: center;
  text-align: center;
}

.chat_col h2 {
  font-weight: lighter;
}

.chat_col a {
  color: var(--text-color);
}

.chat_col li {
  margin: 13px;
  border-radius: 7px;
  background-color: var(--background-color-2);
  padding: 13px;
  transition: .3s;

}

.cols_main li:hover {
  background-color: var(--bg-color-hover);
  cursor: pointer;
}

.cols i {
  font-size: 30px;
}

form {
  /*position: relative;*/
  /*border-radius: 25px;*/
  /*width: 100%;*/
  /*height: 627px;*/
  /*margin: auto;*/

}

textarea {
  background: var(--input-background-color);
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  width: 100% !important;
  border-radius: 7px;
  font-size: 16px;
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: var(--text-color);
  height: 96%;
  resize: none;
}

textarea::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1%);
  border-radius: 10px;
  background-color: #F5F5F5;
}

textarea::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

textarea::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .0);
  background-color: #dfdfdf;
}

textarea:focus {
  outline: none;
}

form button {
  color: rgb(142, 142, 160);
  position: absolute;
  bottom: 26px;
  right: 14px;
  width: 50px;
  cursor: pointer;
  border: none;
  background: none;
  font-size: 20px;
}

form button:hover {
  color: rgb(27, 27, 27);
}

.chat_message {
  background-color: var(--background-color);
}

.chat_message p {
  font-size: 13px;
  text-align: center;
  color: rgb(142, 142, 160);
  margin: 1% 0 2%;
}

.chat_message a {
  color: rgb(142, 142, 160);
}

/*///////////////////////////////responsive/////////////////////////////*/
/*@media (max-width: 1000px)*/
/*{*/
/*    .nav{*/
/*        display: none;*/
/*    }*/
/*    .nav_content{*/
/*        height: 85vh;*/
/*    }*/
/*    .header_toggle{*/
/*        display: block;*/
/*        font-size: 35px;*/
/*        color: black;*/
/*        position: absolute;*/
/*        left: 6px;*/
/*        top: 4px;*/
/*        background: #dbdbdb9e;*/
/*        border-radius: 20px;*/
/*    }*/
/*    .header_close{*/
/*        display: block;*/
/*        font-size: 35px;*/
/*        color: black;*/
/*        position: absolute;*/
/*        left: 6px;*/
/*        top: 4px;*/
/*        background: #8d8d8d;*/
/*        border-radius: 20px;*/
/*    }*/
/*    .show{*/
/*        display: block;*/
/*        width: 100%;*/
/*        height: 85vh;*/
/*    }*/
/*    .main {*/
/*        display: block;*/
/*    }*/
/*}*/
/*@media (max-width: 780px){*/
/*    .chat_col{*/
/*        flex-direction: column;*/
/*    }*/
/*    .chat_message{*/
/*        position: fixed;*/
/*        top: 85%;*/
/*    }*/
/*    .chat_main{*/
/*        height: auto;*/
/*    }*/
/*    .chat_data{*/
/*        margin-bottom: 18%;*/
/*    }*/
/*}*/
/*@media (max-width: 540px)*/
/*{*/
/*    .chat_data{*/
/*        margin-bottom: 28%;*/
/*    }*/
/*    .chat_message p{*/
/*        font-size: 10px;*/
/*        margin-bottom: 4%;*/
/*    }*/
/*    .chat_message{*/
/*        top: 86.5%;*/
/*    }*/
/*    textarea{*/
/*        padding: 11px 37px 11px 11px;*/
/*        font-size: 13px;*/
/*        margin: 3.5% 0;*/

/*    }*/
/*    form button{*/
/*        font-size: 16px;*/
/*        width: 35px;*/
/*    }*/
/*    @media (max-width: 350px){*/
/*        .chat_data{*/
/*            margin-bottom: 38%;*/
/*        }*/
/*    }*/
/*}*/


.Center {
  width: 200px;
  height: 200px;
  background: #0088cc;
  margin: auto;
  padding: 2%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

#btn-bot {
  /*position:absolute;*/
  /*margin-left:40px;*/
  /*left:50%;*/
  /*width:100px;*/
  /*bottom:140px;*/
}


/* CSS */
.button-6 {
  background-color: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  padding: 15px 20px;
  margin: 10px 0;
  transition: .2s all ease-in-out;
}.button-6:hover {
   box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
 }

.copied-message {
  color: white;
  background-color: rgba(0, 0, 0, 0.65);
  padding: 5px;
  border-radius: 5px 5px 5px 1px;
  font-size: 14px;
}

.left_section form {
  position: relative;
}

/*custom*/
.height-100 {
  height: 100%;
}

.main_section {
  display: flex;
}

/*Left Section Css */
.left_section {
  width: 45%;
  margin: 0 10px;
}

.right_section {
  width: 55%;
  margin: 0 10px;
  overflow-y: scroll;
}

.right_section::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #FFFFFF;
}

.right_section::-webkit-scrollbar {
  width: 10px;
  background-color: #FFFFFF;
}

.right_section::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #dfdfdf;
}

.button_outer {
  display: flex;
  justify-content: center;
}
.copy_to_clipboard_icon svg{
  margin: 0 7px;
  cursor: pointer;
  float: right;
  font-size: larger;
  color: lightgrey;
  transition: .1s all ease-in-out;
}.copy_to_clipboard_icon svg:hover{
   color: darkgray;
 }
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-form {
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
}

.login-form h1 {
  margin-top: 0;
  margin-bottom: 1rem;
  text-align: center;
}

.login-form input {
  margin-bottom: 1rem;
  width: 300px;
  padding: 0.5rem;
  font-size: 1rem;
  border: none;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
}

.login-form button {
  padding: 0.5rem;
  font-size: 1rem;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
}

.login-form button:hover {
  cursor: pointer;
  background-color: #388e3c;
}
